// Select

.select {
  border-radius: $border-radius;
  &-label {
    display: block;
    margin-bottom: 4px;
  }
  &-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 6px 20px 6px 10px;
    font-size: 12px;
    background: $gray-light-5;
    height: 100%;
    font-weight: 500;
    cursor: pointer;
  }

  &-box {
    border: 1px solid transparent;
    background: $gray-light-5;
    border-radius: 3px;
    position: relative;
    height: $box-height;
    border-radius: $border-radius;
    color: $gray-3;
    display: block;
    overflow: hidden;
    font-style: normal;
    font-weight: $regular;

    .bp3-icon {
      position: absolute;
      @include vertical-align(absolute);
      right: 6px;
      transition: transform 0.5s ease;
      pointer-events: none;
    }
  }
  &.is-disabled {
    pointer-events: none;
  }
  &.has-warning {
    .select-select {
      color: $warning;
    }
  }

  .bp3-callout {
    margin-top: 5px;
  }
}

.inline-select {
  .select {
    &-box {
      height: 24px;
      .bp3-icon {
        right: 2px;
      }
    }
    &-select {
      padding: 2px 20px 2px 6px;
    }
  }
}
