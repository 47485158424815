// Sidebar

.sidebar {
  $self: &;

  &-title {
    font-size: 16px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  &-section {
    padding: 0 $space-l 0 $space-l;
    border-bottom: 1px solid $gray-light-2;
  }

  &-header {
    padding: $space-l $space-s;
    border-bottom: 1px solid $gray-light-2;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .bp3-button {
      padding-right: 8px;
      padding-left: 8px;
      margin-right: 16px;
      @include vertical-align(absolute);
      left: 16px;
      min-height: 30px;
    }

    .logo {
      flex: 1 1 auto;
      text-align: center;
      margin-top: 4px;
      margin-left: 10px;
      img {
        width: 106px;
      }
    }
    .langLabel {
      width: 20px;
    }
  }

  &-block {
    padding: $space-xs 0;
    &.bp3-skeleton {
      width: 100%;
      margin: 6px 0 0 0;
      min-height: 100px;
    }
    .bp3-input-group {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .bp3-slider {
      margin: 0 0 0 4px;
      width: calc(100% - 8px);
      &-label {
        transform: translate(-20%, 20px);
      }
      &-handle {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        top: 1;

        .bp3-slider-label {
          display: none;
        }
      }
    }

    .caption {
      display: block;
      font-size: 13px;
      font-weight: $regular;
      margin-right: 8px;
      color: lighten($black-font, 30%);
    }

    .bp3-control {
      font-size: 12px;
    }

    .uploadFile {
      &-loadingBar {
        margin: 16px 0;
        width: 100%;
      }
    }
  }

  &-row {
    display: flex;
    align-items: center;
    margin: 6px 0;
    .caption {
      flex: 0 0 50px;
    }
    .bp3-input-group {
      margin-bottom: 0;
    }
    .field-group {
      flex: 1 1 auto;
    }
  }

  &-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: appear-top $general-change;

    .value-input {
      width: 48px;
      height: 30px;
      font-size: 11px;
      padding: 0 8px;
      margin: 0 4px;
    }

    .bp3-slider {
      height: 16px;
      min-width: 120px;
    }

    .bp3-slider-label {
      display: none;
    }
    &-controls {
      border-radius: 4px;
      background: $gray-light-5;
      svg {
        width: 11px;
        height: 11px;
      }
      .control {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        background: transparent;
        border: 1px solid transparent;
        &:hover {
          background: darken($gray-light-5, 2%);
        }
      }
      .control-up {
        border-bottom-color: darken($gray-light-5, 4%);
      }
    }
  }

  &-label {
    margin: 0 0 8px 0;
    font-size: 12px;
    letter-spacing: -0.17px;
    font-weight: $semibold;
    color: $black-font;
    display: flex;
    align-items: center;
    .inline-select {
      width: 48px;
      margin-left: auto;
    }
    &.has-select {
      margin-bottom: 4px;
    }
  }

  &-innerActions {
    display: flex;
    align-items: center;
    padding: 0;
    .bp3-switch {
      margin: 0;
      font-size: 14px;
    }
    .bp3-button {
      flex: 1 1 50%;
      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &-actions {
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;

    .bp3-button {
      margin-top: 10px;
      border-radius: 7px;
    }
  }

  &-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 65px;
    -webkit-overflow-scrolling: touch;
  }

  &-hint {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    color: rgba($gray-2, 0.6);
    padding: 10px 10px 12px;
  }

  &-step {
    cursor: pointer;
    border-radius: 8px;
    background: $gray-light-5;
    margin: 0 0 5px 0;
    position: relative;
    overflow: hidden;
    border: 2px solid $gray-light-5;
    height: 163px;
    animation: appear-top $general-change;
    transition: border $quick-change;

    &.is-overview {
      margin: 5px;
    }

    &-title {
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 4px;
      height: 30px;
      padding: 6px;
      font-size: 12px;
      line-height: 18px;
      font-weight: $semibold;
      letter-spacing: -0.34px;
      background: $gray-light-5;
      max-width: calc(100% - 72px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      z-index: 3;
      color: rgba(0, 0, 0, 0.8);
      align-items: center;
      display: block;
      svg {
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    &-controls {
      position: absolute;
      top: 3px;
      right: 3px;
      z-index: 2;
      .button-icon {
        width: 30px;
        height: 30px;
        min-height: 30px;
        background: rgba(#fff, 0.7);
        -webkit-backdrop-filter: blur(1.25rem);
        backdrop-filter: blur(1.25rem);
        border-radius: 4px;
        margin-left: 3px;
      }
      .unsaved {
        svg {
          color: $warning-2;
        }
      }
    }
    &-gradient {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      z-index: 1;
      background: linear-gradient($gray-light-5, lighten($gray-light-5, 2%));
      z-index: 1;
    }
    &-thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $gray-light-5;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: 2;
        position: relative;
      }
    }
    &.active {
      border-color: #9eaeb9;
      #{$self}-step-title {
        color: $primary;
      }
    }
    &.disabled {
      #{$self}-step-thumb {
        position: relative;
        z-index: 2;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: rgba(255, 255, 255, 0.5);
          z-index: 10;
        }
        &:after {
          content: url('/assets/icons/icon-disable.svg');
          @include centered(absolute);
          opacity: 0.7;
          z-index: 11;
        }
      }
    }
  }
  &-stepType {
    .select,
    .tooltip-check {
      animation: appear-top $quick-change;
    }
  }
}

// Inactive step

.inactive {
  .collapsable-header,
  .collapsable-content {
    background: lighten($gray-light-5, 1%);
  }

  .sidebar-block,
  .collapsable-header > * {
    opacity: 0.4;
  }
}
