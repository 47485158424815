// Info Piece

.infoPiece {
  margin-bottom: 6px;
  font-size: 14px;

  &.caption {
    color: $gray-3;
    font-size: 12px;
  }

  &-label {
    font-weight: $bold;
    margin-right: 4px;
  }

  &-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 6px 0;
    .infoPiece {
      flex: 0 1 33.3%;
      padding-right: 10px;
      margin-bottom: 4px;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  }
}
