// Model settings

.model-settings {
  &-page {
    .thumbs-sidebar {
      padding: 0 6px 6px 6px;
    }
    .sidebar {
      &-block {
        animation: appear-top $general-change;
      }
    }
    .uploadFile {
      margin-bottom: 4px;
    }
  }
}
