// Auth layout

.auth {
  $self: &;
  &-container {
    background: $gray-blue-light;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .bp3 {
      &-form-group {
        margin-bottom: 20px;
      }
      &-label {
        font-size: 15px;
      }

      &-button {
        margin-top: 20px;
        border-radius: 8px;
      }
      &-input {
        background: #fff;
        border: 1px solid $gray-light-3;
        border-radius: 8px;
      }
      &-input-group {
        .bp3-input {
          font-size: 14px;
        }
      }
    }
  }

  &-content {
    flex: 1 1 auto;
    padding: 40px 0;
    overflow-y: auto;
  }

  &-header {
    padding: 10px;
    background: #fff;
    box-shadow: $elevation1;
    flex: 0 0 auto;
    min-height: 55px;
    display: flex;
    align-items: center;
    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      max-width: $layout-maxWidth-wide;
    }
  }

  &-logo {
    width: 112px;
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid lighten($gray-3, 10%);
  }

  &-logoSm {
    width: 26px;
    margin-right: 12px;
    display: none;
  }

  &-project {
    font-size: 18px;
    font-weight: 300;
  }

  &-card {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    border: 1px solid $gray-light-3;
    box-shadow: none;
    padding: 26px;
  }

  &-title {
    margin: 0 0 10px 0;
  }

  &-subtitle {
    font-weight: 400;
    margin-bottom: 20px;
    font-weight: 400;
    margin-bottom: 16px;
    font-size: 14px;
    color: $gray-1;
  }

  &-link {
    display: block;
    padding: 16px 0 0 0;
    text-align: center;
    color: $primary;
  }

  &-footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid $gray-light-3;
  }

  @include mq($until: phone-narrow) {
    &-logoSm {
      display: block;
    }
    &-logo {
      display: none;
    }
  }

  @include mq(tablet) {
    margin-top: -40px;
    &-card {
      @include centered(absolute);
      padding: 36px;
      margin-top: -40px;
    }
    &-project {
      font-size: 20px;
    }
    &-title {
      font-size: 24px;
    }
  }
}
