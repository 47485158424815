// App Layout

.outer-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .main-header {
    flex: 0 0 auto;
  }
  .content {
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .main-footer {
    margin-top: auto;
  }
}
