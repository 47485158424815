// Hotspots

#ui-explorer-dots {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
}

.hotspot {
  $self: &;
  position: absolute;
  font-family: $font;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
  cursor: pointer;

  &-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(#fff, 0.75);
    transition: all 0.1s ease;
    background: $gray-light-6;
    transform: skew(0) translate(-50%, -50%);
    &:before {
      width: 9px;
      height: 9px;
    }
  }

  &-label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $border-radius;
    box-shadow: 0 0.125rem 0.25rem rgba(37, 32, 32, 0.25);
    transition: all 0.3s ease-in-out;
    left: 50%;
    transform: translate(-50%, calc(-100% - 0.625rem)) translateZ(0);
    text-align: center;
    font-weight: 700;
    font-size: 1.0625rem;
    width: max-content;
    height: auto;
    max-width: 12.5rem;
    will-change: auto;
    z-index: 2;
  }

  &-name {
    z-index: 2;
    font-size: 15px;
    position: relative;
    @include translucent-bg($white-transp);
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    display: block;
    font-weight: 400;
    border-radius: $border-radius;
    transition: all 0.2s ease;
  }

  &-halo {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: $border-radius;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    #{$self}-name {
      color: #fff;
      @include translucent-bg(rgba($primary, 0.9));
    }
    #{$self}-dot {
      border-color: $primary-light-4;
      @include translucent-bg(rgba($primary, 0.9));
      box-shadow: 0 0 8px 0 lighten($primary, 10%);
    }
  }

  // Hotspots states

  // Compact - hotspot without label

  &--compact {
    #{$self}-label {
      display: none;
    }
    #{$self}-dot {
      width: 20px;
      height: 20px;
      &:before {
        width: 16px;
        height: 11px;
      }
    }
  }

  &.activated {
    #{$self}-halo {
      animation: halo 0.8s ease-out both;
    }

    #{$self}-name {
      background: $primary;
      color: #fff;
    }

    #{$self}-dot {
      background: $primary;
      border-color: $primary-light-4;
    }
    &.visited {
      #{$self}-dot {
        &:before {
          background: url('/assets/icons/icon-check-blue.svg') no-repeat center
            center;
        }
      }
    }
  }

  &.transition-exit {
    #{$self}-dot {
      background: transparent;
      border-width: 0.125rem;
      border-color: rgba(255, 255, 255, 0.5);
      box-shadow: none;
    }
  }

  &.transition-exit {
    #{$self}-label {
      animation: fade-out 0.4s ease both;
    }
  }

  &.visible {
    #{$self}-label {
      animation: fade-in-simple 0.4s ease;
    }
  }

  &.visited {
    #{$self}-name {
      color: $gray-5;
    }
    #{$self}-dot {
      background: $gray-light-6;
      border-color: $white;
      &:before {
        content: '';
        display: inline-block;
        background: url('/assets/icons/icon-check-gray.svg') no-repeat center
          center;
        @include centered(absolute);
      }
    }
    &.activated {
      #{$self}-name {
        color: #fff;
      }
    }
    &:hover {
      #{$self}-name {
        color: #fff;
      }
      #{$self}-dot {
        background: $primary;
        border-color: $primary-light-4;
        &:before {
          background: url('/assets/icons/icon-check-blue.svg') no-repeat center
            center;
        }
      }
    }
  }

  &.not-visible {
    cursor: auto;
    #{$self}-label {
      display: none;
    }
    #{$self}-dot {
      background: transparent;
      border-color: rgba(255, 255, 255, 0.5);
      &:before {
        background: url('/assets/icons/icon-check-transparent.svg') no-repeat
          center center;
      }
    }
    &:hover {
      #{$self}-dot {
        backdrop-filter: none;
      }
    }
  }
}
