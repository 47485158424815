// Content Editor

.contentEditor {
  $self: &;
  &-content {
    position: relative;
    .button-icon {
      position: absolute;
      top: 6px;
      z-index: 10;
    }
    .mute-button {
      left: 6px;
    }
    .play-pause-button {
      left: 40px;
    }
  }
  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 0;
    min-height: 100vh;
    background-image: radial-gradient(50% 50%, #fbfbfb 50%, #f3f3f3 100%);

    #{$self}-content {
      flex: 1 1 auto;
      width: 0;
    }

    .navigation-sidebar {
      flex: 0 0 $nav-width;
    }

    .thumbs-sidebar,
    .properties-sidebar {
      flex: 0 0 $sidebar-width;
    }

    .ui-instruction-panel {
      position: relative;
    }

    > .bp3-spinner {
      margin-top: -40px;
    }
  }
}

.canvas-wrapper {
  width: 100%;
  position: relative;
}

.storybook-player-preview {
  width: 100%;
  height: 100%;
  background: $gray-light-4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
