// Properties sidebar

.properties {
  &-sidebar {
    // position: fixed;
    // top: 0;
    // right: 0;
    width: $sidebar-width;
    border-left: 1px solid $gray-light-4;
    min-height: 100vh;
    padding: 3px 0 10px 0;
    animation: appear-left 0.1s ease-in-out;
    height: 100vh;
    overflow: auto;
    z-index: 11;
    transform: translateZ(0);
    background: #fff;
    z-index: 11;
    display: block;
    flex: 0 0 $sidebar-width;
    margin-left: auto;

    .sidebar-block {
      padding: 8px 7px 10px;
    }

    .bp3-skeleton {
      width: calc(100% - 20px);
      margin: 10px auto;
    }

    &-header {
      display: flex;
      align-items: center;
      padding: 0 3px 0 7px;

      &.divider {
        padding-top: 6px;
        padding-bottom: 8px;
      }

      .bp3-button {
        margin-left: 3px;
        background: transparent;
      }
    }

    &-title {
      flex: 1 1 auto;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $semibold;
      color: $primary;
      font-size: 13px;
      letter-spacing: -0.37px;
      line-height: 22.5px;
    }

    textarea {
      min-height: 79px;
    }

    .uploadFile {
      &-audio {
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid $gray-light-4;
      }
      &-controls {
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
