// Steps

.steps {
  &-title {
    font-weight: normal;
    font-size: 14px;
    color: darken($gray-3, 10%);
    padding: 10px 20px 10px 24px;
  }

  &-page {
    .thumbs-sidebar {
      display: flex;
      flex-direction: column;
      .sidebar-steps {
        flex: 1 1 auto;
        overflow-y: auto;
      }
      .addStep-button {
        flex: 0 0 auto;
        padding: 6px;
      }
    }
  }
}

.step {
  animation: appear-top 0.3s ease;
  &-features {
    &-input {
      margin-top: 10px;
    }
  }
}
