// Player

// conversion between player-app variables (expectec by player/*.scss) and editor variables
$font-primary: $font;

.canvas-wrapper {
  height: 100vh;
  background-image: -o-radial-gradient(50% 50%, #fbfbfb 50%, #f3f3f3 100%);
  background-image: radial-gradient(50% 50%, #fbfbfb 50%, #f3f3f3 100%);
}

.canvas-wrapper canvas {
  outline: none;
}

.player {
  &.cursor-reposition {
    cursor: url('/assets/images/cursor-reposition.png'), auto;
  }
}

.canvas-still-frame {
  position: absolute;
  left: 0px;
  top: 0px;
}

.ui-instruction-panel {
  .panel {
    z-index: 10;
  }
}

.hotspot {
  &.selected {
    .hotspot {
      &-dot,
      &-name {
        background: $primary;
        color: #fff;
      }
    }
  }
  &.hidden {
    display: none;
  }
}

.player {
  .panel {
    position: absolute;
  }
}
