// Radio tabs

.radio-tabs {
  display: flex;
  background: $gray-light-5;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 2px;
  position: relative;
  height: 36px;
  max-width: 400px;

  &-text {
    display: block;
    z-index: 2;
    position: relative;
    &:focus {
      outline: none;
    }
  }

  .bp3 {
    &-control {
      margin: 0;
      flex: 1 1 50%;
      height: 35px;
      line-height: 35px;
      border-radius: 4px;
      text-align: center;
      font-size: 11px;
      padding: 0;
      z-index: 2;
      position: relative;
      color: darken($gray-1, 5%);
      outline: none;

      .bp3-control-indicator {
        border: none;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: transparent;
        margin: 0;
        transition: background-color 0.2s ease-in-out;
        &:hover {
          background: darken($gray-light-5, 2%);
        }

        &:before {
          background-image: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          z-index: 1;
          z-index: 1;
        }
      }
      &:hover {
        input {
          ~ .bp3-control-indicator {
            background: transparent;
          }
        }
      }
      input {
        &:not(:disabled) {
          ~ .bp3-control-indicator {
            box-shadow: none;
            background: transparent;
          }
          &:active {
            &:checked {
              ~ .bp3-control-indicator {
                background: $gray-light-2;
              }
            }
          }
        }
        &:checked {
          display: none;
          ~ .bp3-control-indicator {
            background: $gray-light-2;
            box-shadow: none;
            &:before {
              background-image: none;
            }
          }
        }
      }
    }
  }
}
