// Utils

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

.link {
  color: $primary;
}

.divider {
  border-bottom: 1px solid rgba($gray-light-2, 0.3);
}
