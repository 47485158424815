// Upload File

.uploadFile {
  $self: &;
  background: $gray-light-5;
  position: relative;
  height: 36px;
  &-wrapper {
    .bp3-progress-bar {
      animation: fade-in $general-change;
      margin: 18px 0 16px;
      // height: 36px;
    }
  }

  .browse-icon {
    @include vertical-align(absolute);
    right: 59px;
    color: $gray-1;
    margin-top: 1px;
  }

  .bp3-file-upload-input {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 4px 95px 4px 10px;
    height: 38px;
    border-radius: $border-radius;
    &:after {
      content: 'Browse';
      right: 4px;
      @include vertical-align(absolute);
      color: $gray-1;
      box-shadow: none;
      margin: 0;
      background: #fff;
      font-weight: normal;
      border-radius: $border-radius;
      height: 26px;
      min-height: 26px;
      width: auto;
      padding: 0 8px 0 31px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      &:after {
        box-shadow: none;
        background: lighten($gray-light-5, 1%);
      }
    }
  }

  &-loadingBar {
    margin: 16px;
    width: calc(100% - 32px);
  }

  &-loaded {
    border-radius: $border-radius;
    #{$self}-audio {
      min-height: 36px;
    }
    .bp3-button {
      background: #fff;

      &.bp3-small {
        width: 27px;
        height: 27px;
        svg {
          width: 12px;
        }
      }
    }
    .infoPiece-group {
      padding: 0;
      .infoPiece {
        padding: 6px 0 6px 12px;
        margin-bottom: 0;
      }
    }
    &.fileAudio {
      #{$self}-audio {
        padding: 5px 5px 5px 8px;
      }
    }
  }

  &-audio {
    padding: 4px 8px 4px 10px;
    display: flex;
    align-items: center;
    background: $gray-light-5;
    border-radius: $border-radius;
    width: 100%;
    border-radius: 4px;
    .bp3-button {
      margin-left: 6px;
      flex-shrink: 0;
    }
  }

  &-name {
    font-size: 12px;
    flex: 1 1 auto;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: darken($gray-3, 10%);
  }

  &-controls {
    display: flex;
    align-items: center;
    padding: 0 12px 0 0;
    background: $gray-light-5;
    height: $box-height;
    border-radius: 4px;
    .button-icon {
      border-radius: 4px;
      flex: 0 0 30px;
    }
    .bp3-progress-bar {
      height: 4px;
      border-radius: 20px;
      background: $gray-light-4;
      &.bp3-intent-primary {
        background: rgba(92, 112, 128, 0.2) !important;
        .bp3-progress-meter {
          background-color: $primary;
        }
      }
    }
  }
  &-time {
    padding-left: 10px;
    font-weight: 400;
    color: $gray-3;
    font-size: 12px;
  }

  &-button {
    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  &-label {
    width: 100%;
  }
}
