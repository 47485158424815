// Color picker

.colorPicker {
  $sample-size: 26px;
  display: flex;
  flex-direction: column;
  margin: 16px 0 12px 0;

  .chrome-picker {
    width: 100% !important;
    margin-top: 12px;
    box-shadow: $elevation2 !important;
    animation: fade-in $general-change;
  }

  &-samples {
    display: flex;
    justify-content: space-between;
    order: -1;
    margin: 0 4px 4px 0;
    .sample-color {
      border-radius: 50%;
      width: $sample-size;
      height: $sample-size;
      position: relative;
      cursor: pointer;
      transition: box-shadow $quick-change;
      &:first-child {
        border: 1px solid darken($gray-light-5, 10%) !important;
        &:after {
          content: '';
          width: calc(#{$sample-size} + 4px);
          height: calc(#{$sample-size} + 4px);
          border: 1px solid transparent;
          display: block;
          border-radius: 50%;
          @include centered(absolute);
        }
        &:before {
          content: '';
          width: calc(#{$sample-size} - 3px);
          height: calc(#{$sample-size} - 3px);
          display: block;
          border-radius: 50%;
          background: transparent;
          border: 1px solid transparent;
          z-index: 2;
          @include centered(absolute);
        }

        &.is-selected {
          &:after {
            border-color: darken($gray-light-5, 10%);
          }
          &:before {
            border-color: darken($gray-light-5, 10%);
            box-shadow: 0px 0px 0px 2px #fff;
          }
        }
      }
    }
    .custom-color {
      position: relative;
      &:after {
        content: url('/assets/icons/icon-tear.png');
        display: block;
        width: 26px;
        height: 26px;
        @include centered(absolute);
      }
    }
  }
}
