// Collapsable

$pad: 16px;

.collapsable {
  $header-height: 38px;
  $self: &;
  width: 100%;
  position: relative;
  background: #fff;

  &-empty {
    #{$self}-chevron {
      display: none;
    }
  }

  .drag-over-area {
    opacity: 0;
    width: 0px;
    height: 0px;
    will-change: height;
    transition: height $general-change;
  }

  &-header {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: $header-height;
    padding: 10px 0 10px 10px;
    border-radius: 8px;
    background: $gray-light-5;
    margin: 4px 4px 5px 4px;
    transition: max-height $general-change;

    &:hover {
      background: lighten($gray-light-5, 2%);
    }

    &.drag-over {
      background: darken($gray-light-5, 2%);

      + .drag-over-area {
        width: 100%;
        height: 159px;
        background: #fff;
      }
    }
  }

  &-loading {
    #{$self}-content {
      padding: 10px 10px 0 10px;
    }
  }

  &-actions {
    margin-left: auto;
  }

  &-content {
    display: none;
    animation: fade-in $general-change;
    .sidebar-block {
      &:last-child {
        padding-bottom: $pad;
      }
    }
    textarea {
      min-height: 72px;
    }
    .select {
      margin-bottom: $space-xs;
    }
  }

  &.is-selected {
    #{$self}-title {
      color: $primary;
    }
  }

  &.is-open {
    #{$self}-content {
      display: block;
    }

    #{$self}-chevron {
      .bp3-icon {
        transform: rotate(180deg);
      }
    }
  }

  &-title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    font-weight: $semibold;
    color: $black-font;
  }

  &-chevron {
    margin-left: auto;
    color: lighten($gray-2, 10%);
    width: $header-height;
    height: $header-height;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: background-color 0.3s ease;

    .bp3-icon {
      transition: transform 0.3s ease;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background: darken($gray-light-5, 1%);
    }
  }

  .sidebar-section {
    border-bottom: none;
    padding: 0;
  }

  .sidebar-step {
    margin: 0 0 2px 10px;
    width: calc(100% - 10px);
  }

  .step-actions {
    padding: 0 26px 30px 26px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .bp3-minimal {
      flex: none;
      background: #fff;
      padding: 10px 24px;
      margin-right: auto;
      width: 100%;
      margin: 0;
      border: 1px solid $gray-light-4;

      &.bp3-intent-danger {
        background: rgba($error, 0.06);
        border-color: rgba($error, 0.2);

        &:hover {
          color: $error;
          background: rgba($error, 0.1);
        }
      }
    }
  }
}

// Inner collapsable

.inner-collapsable {
  $sefl: &;
  width: calc(100% + #{$pad} * 2);
  margin-left: (-$pad);
  // margin-bottom: 16px;
  border-top: 1px solid $gray-light-2;
  border-left: none;
  .collapsable-title {
    color: $black-font;
  }
  &-chevron {
    transition: transform 0.3s ease;
  }
  .inner-collapsable-chevron {
    color: $gray-2;
  }
  &.is-open {
    padding-bottom: $pad;
    .collapsable-header {
      background: transparent;
      .collapsable-title {
        color: $black-font;
      }
    }
    .inner-collapsable-chevron {
      transform: rotate(180deg);
    }
  }
  .collapsable-title {
    color: $black-font;
  }
  &:last-child {
    border-bottom: none;
  }
}
