// Save status

.save-status {
  &-container {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px;
    display: flex;
    align-items: center;
  }

  &-display {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 6px 12px;
    height: 30px;
    margin-left: 4px;
    animation: fade-in $general-change;
    border: 1px solid $gray-light-5;
    .bp3 {
      &-spinner {
        margin: 0;
        padding: 0;
      }
      &-icon-tick {
        color: $primary;
      }
    }
  }

  &-text {
    margin-right: 10px;
    font-size: 11px;
    line-height: 15px;
    color: rgba($gray-3, 0.9);
    font-weight: $regular;
  }
}
