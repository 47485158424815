// Buttons

// Default button styles

button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

// Specific button styles

.bp3-button {
  box-shadow: none;
  &.bp3-intent-primary {
    background: $primary;
    box-shadow: none;
  }
}

.full-button {
  background: $gray-light-5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: $regular;
  padding: 0 20px;
  color: $gray-2;
  height: $box-height;
  &:hover {
    text-decoration: none;
    color: $gray-2;
    background: darken($gray-light-5, 2%);
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background: $gray-light-5;
    }
  }
}

.button-icon {
  background: $gray-light-5;
  display: inline-block;
  border: none;
  outline: none;
  text-align: center;
  width: 28px;
  height: 28px;
  transition: opacity 0.3s ease-in-out;
  border-radius: 4px;
  min-width: 0;
  min-height: 0;
  .bp3-icon {
    color: $gray-1;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &:hover {
    .bp3-icon {
      color: darken($gray-1, 20%);
    }
  }
}

.addStep-button {
  position: relative;
  .button {
    border-radius: $border-radius;
    width: 100%;
    transition: all 0.2s ease;
    outline: none;
    height: 38px;
    border: none;
    min-height: none;
    font-size: 12px;
    line-height: 15px;
    &.bp3-disabled {
      cursor: not-allowed;
    }
  }

  .bp3-icon {
    margin-right: 8px;
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: not-allowed;
  }
  .disabled-help {
    opacity: 0;
  }

  .bp3-popover2-content {
    margin: 0 auto;
    width: 100%;
  }
}

.back-button {
  padding: 6px 6px 6px 0;
}

.mute-button {
  .bp3-icon {
    color: lighten($black-font, 20%);
  }
}
