// alerts

.custom-alert {
  // @include backdrop-filter();
  background: $gray-light-5;
  border-radius: 20px;
  border: 1px solid darken($gray-light-5, 2%);
  padding: 0;
  box-shadow: none;
  overflow: hidden;
  .bp3-alert-body {
    flex-direction: column;
    text-align: center;
    color: $gray-1;
    padding: 20px 30px;
  }

  .icon,
  .bp3-icon {
    margin: 10px auto 20px;
    color: $gray-4;
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  .bp3-icon {
    svg {
      width: 46px;
      height: 46px;
    }
  }

  .bp3 {
    &-heading {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.23px;
      font-weight: $semibold;
      color: $black-font;
    }
    &-alert {
      &-body {
        flex-direction: column;
        text-align: center;
        color: $gray-1;
        p {
          margin-top: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .step {
          display: inline-block;
          font-size: 12px;
          line-height: 17px;
          opacity: 0.8;
          margin: 0 4px 2px 0;
          + .step {
            &:before {
              content: ',';
              position: relative;
              left: -4px;
            }
          }
        }
      }
      &-footer {
        border-top: 1px solid rgba($gray-light-2, 0.4);
        margin-top: 0;
      }
    }
    &-button {
      flex: 1 1 50%;
      font-size: 14px;
      line-height: 18px;
      font-weight: $regular;
      background: transparent;
      margin: 0;
      padding: 22px;
      &:hover {
        background: darken($gray-light-5, 4%);
        border-radius: 0;
      }
      &[class*='bp3-intent'] {
        font-weight: $semibold;
        border-left: 1px solid rgba($gray-light-2, 0.4);
      }
      &.bp3-intent-primary {
        color: $primary;
      }
      &.bp3-intent-danger {
        color: $error;
      }
      &.bp3-intent-warning {
        color: $warning;
      }
    }
  }
}
