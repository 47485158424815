// Toasts

.custom-toast {
  // @include backdrop-filter();
  background: $gray-light-5;
  box-shadow: none;
  outline: none;
  border: 1px solid $gray-light-4;
  border-radius: 10px;
  z-index: 99;

  &-container {
    position: relative;
  }
  .bp3 {
    &-icon {
      color: $gray-4;
    }
    &-toast {
      &-message {
        color: $gray-1;
        font-weight: $regular;
        display: flex;
        align-items: center;
      }
    }

    &-button {
      color: $primary;
      &:hover {
        background: darken($gray-light-5, 2%) !important;
        color: $primary !important;
      }
      &-group {
        padding-right: 8px;
      }
    }
    &-icon-cross {
      border: 2px solid $gray-1;
      border-radius: 50%;
    }
  }

  &-big {
    border-radius: 20px;
    padding: 12px 30px;
    .bp3 {
      &-icon {
        margin: 0 10px 0 0;
        svg {
          width: 51px;
          height: 51px;
        }
      }
      &-button {
        &-group {
          display: none;
        }
      }
      &-toast {
        &-message {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .chart-pie {
    margin: 0 19px 0 6px;
  }
}

.toaster-visible {
  &.bp3-overlay-open {
    z-index: 999;
  }
  .custom-toast {
    border-color: $gray-light-3;
    box-shadow: 1px 1px 14px -5px rgba(#000, 0.1);
  }
}
