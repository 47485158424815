// Phone preview

.player {
  &.phone-preview-active {
    .phone-preview-container {
      display: block;
    }
    .ui-instruction-panel {
      display: none;
    }

    .canvas-wrapper {
      background: transparent;
      display: flex;
      height: 85vh;
      top: 7.5vh;
    }

    + #ui-explorer-dots {
      display: flex;
      height: 85vh;
      top: 7.5vh;
    }
  }
}

.phone {
  &-preview {
    &-button {
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 10;
    }

    &-container {
      display: none;
    }

    &-frame {
      border-radius: 10px;
      display: block;
      height: 85vh;
      width: 47.8vh; //9/16 * 85
      @include centered(absolute);
      border: 2px dashed $gray-4;
      overflow: hidden;
      pointer-events: none;
      z-index: 11;
      &:before,
      &:after {
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      &:before {
        background-image: url('../../assets/images/preview-mobile-logo.png');
        background-position: top left;
        top: 0;
        left: 0;
      }
      &:after {
        background-image: url('../../assets/images/preview-mobile-ui.svg');
        background-position: bottom center;
        bottom: 0;
        left: 0;
      }
    }
  }
}
