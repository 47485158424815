// Panel

.panel {
  position: fixed;
  font-family: $font-primary;
  @include translucent-bg($white-transp);
  border-radius: 0.75rem;
  max-width: 37.5rem;
  width: 94%;
  height: 5.25rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.blocked-input {
    pointer-events: none;
    touch-action: none;
  }

  &-fwd,
  &-back {
    background: none;
    border: none;
    height: 100%;
    outline: none;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none; /* iOS Safari */
    flex: 0 0 2.5rem;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 1.25rem;
    }

    &:hover {
      background: none;
    }
  }

  &-back {
    &:active {
      background: linear-gradient(
        270deg,
        rgba(50, 50, 50, 0.05) 0%,
        rgba(50, 50, 50, 0) 100%
      );
    }
  }

  &-fwd {
    &:active {
      background: linear-gradient(
        90deg,
        rgba(50, 50, 50, 0.05) 0%,
        rgba(50, 50, 50, 0) 100%
      );
    }
  }

  &-frame {
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0 0.25rem;
  }

  &-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    height: 100%;
    width: 100%;
  }

  &-content,
  &-contentPreview {
    order: 2;
    margin: auto;
    flex: 1 0 100%;
    color: #000;
    letter-spacing: -0.0106rem;
    text-align: center;
    line-height: 2rem;
    width: 100%;
    white-space: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .progress-bar-container {
    background-color: rgba(150, 150, 150, 0.2);
    height: 0.1875rem;
    border-radius: 0.125rem;

    .progress-bar {
      background-color: #0092ff;
      height: 0.1875rem;
      border-radius: 0.125rem;
    }
  }

  // Mobile landscape

  @media only screen and (max-device-width: 56.25rem) and (max-device-height: 30rem) and (orientation: landscape) {
    height: 5.25rem;
    left: 5rem;
    min-height: 5.25rem;
    bottom: 1.875rem;
    transform: none;

    &-content,
    &-contentPreview {
      letter-spacing: -0.005rem;
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }

    button {
      flex: 0 0 3.75rem;

      img {
        height: 1.375rem;
      }
      &-back,
      &-fwd {
        &:hover {
          background: none;
        }
      }
      &-back {
        &:active {
          background: linear-gradient(
            270deg,
            rgba(50, 50, 50, 0.05) 0%,
            rgba(50, 50, 50, 0) 100%
          );
        }
      }
      &-fwd {
        background: linear-gradient(
          90deg,
          rgba(50, 50, 50, 0.05) 0%,
          rgba(50, 50, 50, 0) 100%
        );
      }
    }
  }

  // Tablet

  @include mq(tablet) {
    height: 7.5rem;
    border-radius: 0.875rem;
    bottom: 5.625rem;
    max-width: 45.3125rem;
    &-content,
    &-contentPreview {
      font-size: 1.375rem;
      line-height: 1.3;
    }

    .frame {
      padding: 0 0.75rem;
    }

    button {
      flex-basis: 5rem;

      img {
        height: 1.625rem;
      }
    }
  }

  // Desktop

  @include mq(desktop) {
    &-content,
    &-contentPreview {
      font-size: 1.625rem;
    }
    button {
      img {
        height: 2.125rem;
      }
    }
  }
}

// Fixes IOS

@supports (-webkit-touch-callout: none) {
  .panel {
    background: rgba(255, 255, 255, 0.8);
  }
}

// Sync panel

.sync-panel {
  position: absolute;
  right: 2.5rem;
  top: calc(50% - 12.5rem);
  width: 18.75rem;
  height: 25rem;
  color: #22ff22;
  padding: 1.25rem;
  background-color: #222;
  backdrop-filter: blur(1.25rem);
  line-height: 1em;

  .stream {
    &-circle {
      background-color: $error;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 0.625rem;
      margin-right: 0.3125rem;
    }

    &-button {
      float: right;
      text-decoration: underline;
      display: flex;
      align-items: center;
    }
  }
}

// Presentation panel & session panel

.presentation-panel,
.session-panel {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 3.125rem;
  background-color: #222;
  color: #f1f1f1;
  line-height: 1em;
  padding: 1.25rem;
}

.session-panel {
  .session-link {
    color: #bbb;
    cursor: pointer;
  }

  .icon {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.3125rem;
    left: -0.1875rem;
    cursor: pointer;
  }
  @media only screen and (max-device-width: 56.25rem) {
    top: 5.625rem;
    white-space: nowrap;
  }
}
