// ------------------------------------
// $VARIABLES
// ------------------------------------

// Colors

$gray-1: #5c7680;
$gray-2: #6d8292;
$gray-3: #8a9ba8;
$gray-4: #d6dcdf;
$gray-5: #afafaf;
$gray-light-1: #ced9e0;
$gray-light-2: #d8e1e8;
$gray-light-3: #e1e8ed;
$gray-light-4: #ebf1f5;
$gray-light-5: #f9f9f9;
$gray-light-6: #f2f2f2;
$gray-blue-light: #f5f8fa;
$primary: #0092ff;
$success: #0f9960;
$warning: #d9822b;
$warning-2: #e38f58;
$error: #db3737;
$blue-dark-1: #202b35;
$white-transp: rgba(255, 255, 255, 0.7);

$black-font: #182026;
$primary-light-4: rgba($primary, 0.1);
$primary-light-5: rgb(244, 249, 255);

// Fonts
$font-base: 16;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$font: 'Inter', sans-serif;

$space-xs: 8px;
$space-s: 12px;
$space-m: 14px;
$space-l: 16px;
$space-xl: 26px;

$elevation1: 1px 1px 10px 0 rgba(0, 0, 0, 0.08);
$elevation2: 0 5px 8px rgba($blue-dark-1, 0.1);
$border-radius: 4px;
$nav-width: 63px;
$sidebar-width: 244px;
$box-height: 38px;

// Layout
$layout-maxWidth: 975px;
$layout-maxWidth-wide: 1460px;

// Breakpoints
$grid-breakpoints: (
  phone-narrow: 460px,
  phone: 667px,
  tablet: 768px,
  desktop: 1024px,
  desktop-wide: 1280px,
);
