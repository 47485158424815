// Spinner

.bp3-spinner {
  padding: 20px;
  &.bp3-intent-primary {
    .bp3-spinner-head {
      stroke: $primary;
    }
  }
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;
  .bp3-spinner {
    @include centered(absolute);
    z-index: 10;
    // background: #fff;
    border-radius: 50%;
    padding: 0;
  }
}

.overlay {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
