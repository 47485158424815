// Trigger selection

.triggerSelection {
  background: #fff;
  &-button {
    display: inline-block;
    outline: none;
    line-height: 0.6;
    margin-left: auto;
    padding: 5px;
    height: 100%;
    text-align: center;

    &.is-active {
      background: $gray-light-4;
      color: $primary;
      svg {
        color: $gray-2;
      }
    }

    &:not([class*='bp3-intent-']) {
      border: none;
      border: 1px solid $gray-light-4;
    }

    svg {
      color: $gray-1;
      width: 20px;
      height: 20px;
    }
  }

  .bp3-input-action {
    bottom: 0;
  }

  .bp3-input-group {
    .bp3-button {
      margin: 0;
      min-width: 40px;
    }

    .bp3-input {
      padding-right: 40px;
    }
  }
}
