// Lang widget

.langWidget {
  $self: &;
  &-add {
    display: flex;
    align-items: flex-start;

    .bp3-button {
      margin-left: 4px;
      height: 36px;
      width: 36px;
      flex: 0 0 36px;
      .bp3-icon {
        color: $primary;
      }
    }
  }
  &-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid darken($gray-light-5, 2%);
    transition: all 0.3s ease-in-out;
    animation: appear-top 0.6s ease;
    &.is-active {
      .select-box {
        border-color: rgba($primary, 0.2);
      }
      .bp3-radio {
        font-weight: bold;
      }
    }
  }

  .lang {
    flex: 1 1 auto;
  }

  .bp3-control {
    margin: 0;
  }

  .select {
    flex: 1 1 auto;
  }

  .button-icon {
    flex: 0 0 auto;
    margin-left: auto;
    background: $gray-light-5;
    border-radius: $border-radius;
    padding: 0;
    width: 30px;
    height: 30px;
    text-align: center;

    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background: rgba($error, 0.06);
      svg {
        color: $error;
      }
    }
  }

  .bp3-control {
    flex: 1 1 auto;
    width: 100%;
    padding: 10px 10px 10px 30px;
  }
}

.langLabel {
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
  font-weight: $bold;
  color: $primary;
}
