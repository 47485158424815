// Navigation Sidebar

.navigation {
  &-sidebar {
    z-index: 2;
    left: 0;
    top: 0;
    width: $nav-width;
    padding: 3px;
    border-right: 1px solid rgba($gray-light-2, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateZ(0);
    background: #fff;
    z-index: 2;
    .bp3-skeleton {
      width: calc(100% - 20px);
      margin: 10px auto;
    }

    .bp3-skeleton {
      margin: 0;
      padding: 0;
      border: 1px solid transparent !important;

      &:first-child {
        margin-top: 2px;
      }
    }

    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 55px;
      height: 55px;
      background: $gray-light-5;
      border-radius: 4px;
      margin-bottom: 4px;
      border: 2px solid $gray-light-5;
      outline: none;
      box-shadow: none;
      padding-top: 2px;
      transition: border 0.6s ease, color $general-change;
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
      &:hover {
        color: inherit;
      }
      &-icon {
        margin-bottom: 1px;
        color: $gray-1;
        svg {
          height: 21px;
          width: 21px;
        }
        g {
          fill: $gray-1;
        }
      }
      &-text {
        font-size: 9px;
        line-height: 13px;
        letter-spacing: -0.09px;
        color: $gray-1;
        font-weight: $medium;
      }
      &.active {
        border-color: $gray-light-2;
        .nav-item {
          &-text {
            color: $primary;
          }
          &-icon {
            color: $primary;
            g,
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}
