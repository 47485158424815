// Dropdown tree

.react-dropdown-tree-select {
  &.dropdown-tree {
    margin-top: 4px;
    .dropdown {
      width: 100%;
      .dropdown-trigger {
        border-radius: 4px;
        border-color: $gray-light-5;
        background: $gray-light-5;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 7px 4px;

        &.arrow {
          &.bottom,
          &.top {
            &:after {
              content: url('/assets/icons/icon-chevron-down.svg');
              transition: transform $general-change;
              padding: 0 2px;
              position: absolute;
              top: 8px;
              right: 6px;
              height: 22px;
              opacity: 0.8;
            }
          }
          &.top {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
      .dropdown-content {
        width: 100%;
        animation: fade-in $general-change;
        box-shadow: none;
        padding: 0;
        border: none;
        position: relative;
      }
    }
  }

  .no-matches {
    font-size: 12px;
    margin: 0 2px;
    color: $gray-1;
  }

  .dropdown {
    &-trigger {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .tag {
    border-radius: 4px;
    font-size: 11px;
    min-width: 20px;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 5px;
    margin: 0 4px 4px 0;
    color: $gray-1;
    background: #fff;
    &-list {
      max-width: calc(100% - 20px);
      display: block;
    }
    &-item {
      margin: 0;
      .search {
        font-size: 12px;
        background: $gray-light-5;
        border-bottom: none;
        padding: 4px 0 4px 4px;
        color: $gray-1;
      }
    }
    &:focus-within {
      border-color: $gray-light-3;
      background-color: $gray-light-5;
    }
    &-remove {
      font-size: 8px;
      margin-left: 4px;
      position: relative;
      width: 14px;
      &:hover {
        &:after {
          background: darken($gray-light-5, 2%);
        }
      }
      &:after {
        content: url('/assets/icons/icon-delete.svg');
        position: absolute;
        top: -4px;
        right: -2px;
        background: #fff;
        padding: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
    }
  }
  .toggle {
    font-style: initial;
    position: relative;
    left: 2px;
    top: -3px;
    height: 14px;
    &:after {
      text-align: center;
      display: inline-block;
      width: 14px;
      height: 14px;
    }
    &.collapsed {
      &:after {
        content: url('/assets/icons/icon-plus.svg');
      }
    }
    &.expanded {
      &:after {
        content: url('/assets/icons/icon-minus.svg');
      }
    }
  }

  .node {
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    margin: 0 auto 4px;

    &.leaf,
    &.tree {
      padding-left: 10px !important;
    }

    &-label {
      font-size: 12px;
      position: relative;
      padding-left: 20px;
      color: darken($gray-1, 5%);
      &:before {
        content: '';
        width: 14px;
        height: 14px;
        border: 1px solid $gray-3;
        position: absolute;
        top: 0;
        left: 0;
        transition: all $general-change;
      }
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
        transition: all $general-change;
      }
      &:hover {
        &:before {
          background: $gray-light-5;
        }
      }
    }
    &-focused {
      background: $gray-light-5;
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    &[type='radio'] {
      &:checked {
        + .node-label {
          &:before {
            border-color: $primary;
          }
          &:after {
            background: $primary;
          }
        }
      }
      + .node-label {
        &:before {
          border-radius: 50%;
        }
      }
    }

    &[type='checkbox'] {
      &:checked {
        + .node-label {
          &:before {
            border-color: $primary;
            content: url('/assets/icons/icon-tick-primary.svg');
          }
        }
      }
      + .node-label {
        &:before {
          border-radius: 4px;
        }
      }
    }
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $gray-3;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $gray-3;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $gray-3;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $gray-3;
  }
  .infinite-scroll-component {
    // max-height: calc(100vh - 700px);
    box-shadow: $elevation2;
    overflow-x: hidden;
    // border: 1px solid rgba($blue-dark-1, 0.06);
  }

  .disabled {
    cursor: pointer;
    position: relative;
    .toggle {
      width: calc(100% - 10px);
      position: absolute;
      height: 28px;
      cursor: pointer;
      z-index: 2;
      margin: 0;
      &:after {
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
    label {
      padding-left: 8px;
      z-index: 1;
    }
    .node-label {
      padding-left: 0;
      cursor: pointer;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
