// Mixins

// Media queries

$mq-base-font-size: 16px !default;

/// Responsive mode

$mq-responsive: true !default;

$grid-breakpoints: (
  phone-narrow: 460px,
  phone: 568px,
  tablet: 768px,
  desktop: 1000px,
  desktop-medium: 1180px,
  desktop-wide: 1500px,
) !default;

/// Static breakpoint (for fixed-sized layouts)

$mq-static-breakpoint: desktop !default;

/// Show breakpoints in the top right corner

$mq-show-breakpoints: () !default;

/// Customize the media type (e.g. `@media screen` or `@media print`)

$mq-media-type: all !default;

/// Convert pixels to ems

@function mq-px2em($px, $base-font-size: $mq-base-font-size) {
  @if unitless($px) {
    @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
    @return mq-px2em($px * 1px, $base-font-size);
  } @else if unit($px) == em {
    @return $px;
  }
  @return ($px / $base-font-size) * 1em;
}

/// Get a breakpoint's length

@function mq-get-breakpoint-length($name, $breakpoints: $grid-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
  }
}

/// Parse a breakpoint

@function mq-parse-breakpoint(
  $value,
  $exclusive: false,
  $breakpoints: $grid-breakpoints
) {
  @if type-of($value) == number {
    @return mq-px2em($value);
  } @else {
    @return mq-px2em(mq-get-breakpoint-length($value, $breakpoints)) -
      if($exclusive, 0.01em, 0);
  }
}

/// Joins all elements of `$list` with `$glue`.

@function mq-stringify($list, $glue: ' and ') {
  $result: '';

  @each $item in $list {
    $result: $result + if(length($item) > 1, mq-stringify($item, $glue), $item);

    @if $item != nth($list, -1) {
      $result: $result + $glue;
    }
  }

  @return quote($result);
}

/// Media Query Function
///
///  $mq-palm: mq($until: tablet);
///
///  $mq-lap: mq(mobile, tablet);
///
///  $mq-portable: mq($from: tablet, $and: '(orientation: landscape)');
///
///  $mq-desk-small: mq(950px) {
///
///  $mq-portable-screen: mq(tablet, $media-type: screen) {
///
///  // Advanced use:
///  $my-breakpoints: ( L: 900px, XL: 1200px );
///  $mq-custom: mq(L, $breakpoints: $my-breakpoints, $static-breakpoint: L);
@function mq(
  $from: false,
  $until: false,
  $and: false,
  $or: false,
  $media-feature: width,
  $media-type: $mq-media-type,
  $breakpoints: $grid-breakpoints,
  $static-breakpoint: $mq-static-breakpoint
) {
  $min-value: 0;
  $max-value: 0;
  $media-query: ();

  // From: this breakpoint (inclusive)
  @if $from {
    $min-value: mq-parse-breakpoint($from, false, $breakpoints);
  }

  // Until: that breakpoint (exclusive)
  @if $until {
    $max-value: mq-parse-breakpoint($until, true, $breakpoints);
  }

  @if $media-feature {
    @if $min-value != 0 {
      $media-query: append(
        $media-query,
        '(min-#{$media-feature}: #{$min-value})'
      );
    }
    @if $max-value != 0 {
      $media-query: append(
        $media-query,
        '(max-#{$media-feature}: #{$max-value})'
      );
    }
  }

  @if $and {
    $media-query: append($media-query, '#{$and}');
  }

  $media-query: mq-stringify($media-query, ' and ');

  // Prevent unnecessary media query prefix 'all and '
  @if ($media-type != 'all' and $media-query != '') {
    $media-query: '#{$media-type} and #{$media-query}';
  } @else if $media-query == '' {
    $media-query: $media-type;
  }

  @if $or {
    $media-query: append($media-query, '#{$or}');
    $media-query: mq-stringify($media-query, ', ');
  }

  $media-query: unquote(#{$media-query});

  @return $media-query;
}

/// Media Query Mixin
///
/// @example scss
///  .element {
///    @include mq($from: mobile) {
///      color: red;
///    }
///    @include mq($until: tablet) {
///      color: blue;
///    }
///    @include mq(mobile, tablet) {
///      color: green;
///    }
///    @include mq($from: tablet, $and: '(orientation: landscape)') {
///      color: teal;
///    }
///    @include mq(950px) {
///      color: hotpink;
///    }
///    @include mq(tablet, $media-type: screen) {
///      color: hotpink;
///    }
///    // Advanced use:
///    $my-breakpoints: ( L: 900px, XL: 1200px );
///    @include mq(L, $breakpoints: $my-breakpoints, $static-breakpoint: L) {
///      color: hotpink;
///    }
///  }
@mixin mq(
  $from: false,
  $until: false,
  $and: false,
  $or: false,
  $media-feature: width,
  $media-type: $mq-media-type,
  $breakpoints: $grid-breakpoints,
  $responsive: $mq-responsive,
  $static-breakpoint: $mq-static-breakpoint
) {
  // Responsive support is disabled, rasterize the output outside @media blocks
  // The browser will rely on the cascade itself.
  @if ($responsive == false) {
    $min-value: 0;
    $max-value: 0;

    // From: this breakpoint (inclusive)
    @if $from {
      $min-value: mq-parse-breakpoint($from, false, $breakpoints);
    }

    // Until: that breakpoint (exclusive)
    @if $until {
      $max-value: mq-parse-breakpoint($until, true, $breakpoints);
    }

    $static-breakpoint-value: mq-get-breakpoint-length(
      $static-breakpoint,
      $breakpoints
    );
    $target-value: mq-px2em($static-breakpoint-value);

    // Output only rules that start at or span our target value
    @if (
      $and ==
        false and
        $min-value <=
        $target-value and
        ($until == false or $max-value >= $target-value)
    ) {
      @content;
    }
  }

  // Responsive support is enabled, output rules inside @media queries
  @else {
    $media-query: mq(
      $from,
      $until,
      $and,
      $or,
      $media-feature,
      $media-type,
      $breakpoints,
      $static-breakpoint
    );

    @media #{$media-query} {
      @content;
    }
  }
}

/// Add a breakpoint

@mixin mq-add-breakpoint($name, $length) {
  $new-breakpoint: (
    $name: $length,
  );
  $grid-breakpoints: map-merge($grid-breakpoints, $new-breakpoint) !global;
}

/// Show the active breakpoint in the top right corner of the viewport

///  // Show breakpoints using global settings
///  @include mq-show-breakpoints;
///
///  // Show breakpoints using custom settings
///  @include mq-show-breakpoints((L, XL), (S: 300px, L: 800px, XL: 1200px));
@mixin mq-show-breakpoints(
  $show-breakpoints: $mq-show-breakpoints,
  $breakpoints: $grid-breakpoints
) {
  body:before {
    background-color: #fcf8e3;
    border-bottom: 1px solid #fbeed5;
    border-left: 1px solid #fbeed5;
    color: #c09853;
    font: small-caption;
    padding: 3px 6px;
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    // Loop through the breakpoints that should be shown
    @each $show-breakpoint in $show-breakpoints {
      $length: mq-get-breakpoint-length($show-breakpoint, $breakpoints);
      @include mq($show-breakpoint, $breakpoints: $breakpoints) {
        content: '#{$show-breakpoint} ≥ #{$length} (#{mq-px2em($length)})';
      }
    }
  }
}

@if length($mq-show-breakpoints) > 0 {
  @include mq-show-breakpoints;
}

// Vertical align

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin centered($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Translucent bg

@mixin translucent-bg($color) {
  background: $color;
  backdrop-filter: blur(1.25rem);
}

//  Text overflow with ellipsis

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
