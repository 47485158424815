// Tooltips

.tooltip {
  &-check {
    display: flex;
    align-items: center;
    margin: 12px 0 0 2px;

    .bp3-popover2-content {
      width: 200px;
    }

    .bp3-control {
      margin-bottom: 0;
    }

    .question {
      display: block;
      outline: none;
    }

    .bp3-overlay {
      outline: none;
    }
    &.disabled {
      .bp3-control {
        opacity: 0.5;
      }
    }
  }

  &-question {
    font-size: 12px;
    width: 16px;
    height: 17px;
    font-weight: $regular;
    border-radius: 50%;
    color: $gray-3;
    border: 1px solid rgba($gray-1, 0.2);
    display: inline-block;
    text-align: center;
    cursor: pointer;
    margin-left: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    position: relative;
    top: -1px;
    &:hover {
      background: lighten($gray-light-5, 1%);
    }
    .question {
      display: block;
    }
  }
}

.bp3-popover2-content {
  border: 1px solid $gray-light-5;
  background: #fff;
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
  max-width: 220px;
  margin-left: 10px;
  animation: fade-in $quick-change;
  color: $gray-1;
  text-align: center;
  font-weight: normal;
}
