// Thumbs sidebar

.thumbs {
  $self: &;
  &-sidebar {
    width: $sidebar-width;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    transform: translateZ(0);
    background: #fff;
    z-index: 11;

    &-main {
      overflow: auto;
      max-height: calc(100vh - 50px);
      border-bottom: 1px solid $gray-light-5;
      flex: 1 1 auto;
      scroll-behavior: smooth;

      &.prevent-smooth-scroll {
        scroll-behavior: initial;
      }
    }
    .thumb {
      &-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
      }
      &-image {
        height: 120%;
        @include centered(absolute);
      }
    }
    .addStep-button {
      flex: 0 0 auto;
      position: relative;
      &:before {
        content: '';
        width: 2px;
        height: 100%;
        background: #fff;
        position: absolute;
        left: -1px;
        top: 0px;
      }
    }
  }
}
