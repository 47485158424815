// Fields

.field {
  &-row {
    display: flex;
    align-items: center;
    margin-bottom: $space-l;

    .bp3-switch {
      margin: 0 0 0 2px;
    }

    .bp3-input-group {
      flex: 1 1 auto;
    }
  }

  &-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .bp3-input-group {
      flex: 0 1 calc(33.3% - 4px);
      margin: 0 $space-xs $space-xs 0;
      position: relative;
      display: flex;
      align-items: center;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .bp3-input {
      padding: 6px 24px 6px 6px !important;
    }

    .bp3-input-action {
      color: $gray-2;
      font-weight: $bold;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 8px 0 0;
      font-size: 14px;
    }

    .bp3-input-left-container {
      padding: 6px 6px 6px 2px;
      display: block;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.textarea {
  &-wrapper {
    position: relative;
  }
  &-counter {
    font-size: 10px;
    color: $gray-2;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}
