// Lang editor

// Languages page
.languages-page {
  .canvas-wrapper,
  .ui-instruction-panel {
    display: none;
  }
}

.lang {
  &Editor {
    $self: &;
    &-container {
      width: 100%;
      padding: 30px 40px;
      overflow: auto;
      width: 100%;
      height: 100vh;
      background-color: #fff;
      z-index: 12;
      position: relative;
      overflow: hidden;

      .question {
        margin-top: 1px;
      }
    }
    &-title {
      font-size: 24px;
      line-height: 18px;
      letter-spacing: -0.34px;
      margin-bottom: 24px;
      font-weight: $semibold;
    }
    &-subtitle {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.1;
    }
    &-tabs {
      width: 100%;
      animation: appear-top $general-change;
      &Items {
        margin-top: 16px;
        display: flex;
        align-items: flex-end;
        .langWidget {
          flex: 0 0 200px;
          margin-left: auto;
          margin-bottom: 6px;
        }
      }
      &List {
        white-space: nowrap;
        overflow-x: auto;
        margin-right: 4px;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          height: 4px;
          &-thumb {
            background-color: lighten($gray-3, 20%);
            border-radius: 20px;
          }
        }
      }
    }
    &-tab {
      display: inline-flex;
      background: $gray-light-5;
      margin-right: 6px;
      border-radius: 4px 4px 0 0;
      min-width: 120px;
      font-size: 12px;
      line-height: 15px;
      color: $gray-3;
      overflow: hidden;
      &Item {
        padding: 12px 0 12px 15px;
        flex: 1 1 auto;
        transition: color $quick-change;
      }
      .delete-button {
        color: $gray-3;
        flex: 0 0 auto;
        opacity: 0.8;
        padding: 10px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .is-selected {
        color: $primary;
        + .delete-button {
          color: $primary;
        }
      }
    }

    &-table {
      overflow: auto;
      max-height: calc(100vh - 180px);
      &Header {
        display: flex;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.1px;
        font-weight: $medium;
        color: $gray-1;
        border-top: 1px solid darken($gray-light-5, 3%);
        border-bottom: 1px solid darken($gray-light-5, 3%);
        #{$self}-cell {
          padding: 12px 18px;
          background: $gray-light-5;
        }
      }

      .is-type {
        flex: 0 0 100px;
        background: $gray-light-5;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .is-title {
        flex: 0 0 240px;
      }

      .is-description {
        flex: 0 0 30%;
        min-width: 360px;
      }

      .is-audio {
        flex: 1 1 auto;
        border-right: none;
        min-width: 440px;
        position: relative;
        &:before,
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background: $gray-light-4;
          position: absolute;
          left: 0;
        }
        &:before {
          top: -1px;
        }
        &:after {
          bottom: -1px;
        }
      }

      .fileAudio {
        display: flex;
        align-items: center;
      }

      .uploadFile {
        width: 214px;
        &-audio {
          margin-right: 4px;
          width: 214px;
        }
        &-controls {
          position: relative;
          .bp3-progress-bar {
            width: 90px;
          }
        }
        .browse-icon {
          right: 57px;
          margin-top: 0;
          svg {
            width: 12px;
          }
        }
      }

      .bp3 {
        &-file-upload-input {
          background: #fff;
          border: 1px solid $gray-light-3;
          &:after {
            border: 1px solid $gray-light-3;
            height: 20px;
            min-height: 20px;
            padding: 0 6px 0 24px;
            border-radius: 5px;
          }
        }
      }
    }

    &-cell {
      padding: 4px 8px;
      border-right: 1px solid darken($gray-light-5, 3%);

      .bp3-input {
        background: transparent;
        color: $gray-1;
        font-size: 11px;
        width: 100%;
        height: auto;
        overflow: hidden;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      textarea {
        padding: 10px;
        min-height: 20px;
      }
      .textarea-counter {
        background: $gray-light-5;
        padding: 2px 4px;
        border-radius: 4px;
        bottom: 1px;
        right: 1px;
      }
    }

    &-row {
      display: flex;
      border-bottom: 1px solid darken($gray-light-5, 3%);
      position: relative;
    }

    &-label {
      text-transform: uppercase;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.1px;
      font-weight: $medium;
      color: $gray-1;
      font-weight: $medium;
    }
  }
}
