@import '~@blueprintjs/core/lib/scss/variables';

// Blueprint Theme

// Override general blueprint styles

:focus {
  outline: none;
}

.bp3 {
  // Toast

  &-skeleton {
    transition: height $general-change;
  }

  &-toast {
    align-items: center;
    font-weight: $semibold;

    &.bp3-intent-primary {
      background-color: $primary;
    }

    .bp3-button {
      border: none !important;
      outline: none;
      align-self: flex-start;
    }
  }

  &-callout {
    font-size: 11px;
    line-height: 14px;
    font-weight: $regular;
    color: rgba($blue-dark-1, 0.8);
    margin-bottom: 6px;
    max-width: 300px;
    min-height: 38px;
    &.bp3-intent-warning {
      background: rgba($warning, 0.08);
    }
  }

  // Slider

  &-slider {
    width: calc(100% - 20px);
    margin: 10px;
    &-handle {
      outline: none;
    }
    &-progress {
      &.bp3-intent-primary {
        background-color: $gray1;
      }
    }
  }

  // heading

  &-heading {
    font-weight: $bold;
  }

  // Dialog

  &-dialog {
    border-radius: $border-radius;
    padding: 30px;
    background: #fff;
    box-shadow: $elevation1;
    border: 1px solid $gray-light-3;
    .bp3-icon {
      margin-right: 16px;
    }
    p {
      margin-bottom: 10px;
    }
  }

  // Overlay backdrop

  &-overlay-backdrop {
    // background: rgba(255, 255, 255, 0.6);
    background: rgba(0, 0, 0, 0.04);
  }

  // Control

  &-control {
    color: lighten($black-font, 30%);
    &-indicator {
      outline: none !important;
    }

    &:hover {
      input:checked {
        ~ .bp3-control-indicator {
          background: $primary;
        }
      }
      input {
        ~ .bp3-control-indicator {
          background: $gray-light-4;
        }
      }
    }

    &.bp3-switch {
      &:hover {
        input:checked {
          ~ .bp3-control-indicator {
            background: darken($primary, 10%);
          }
        }
      }
      input {
        &:checked {
          ~ .bp3-control-indicator {
            background: $primary;
          }
        }
      }
    }
    input:checked {
      ~ .bp3-control-indicator {
        background: $primary;
      }
    }
  }
  .bp3-control:hover
    input:checked
    ~ .bp3-control-indicator
    &-tab-indicator-wrapper {
    ~ .bp3-tab {
      outline: none;
    }
  }

  // Input

  &-input {
    box-shadow: none;
    border: 1px solid transparent;
    resize: none;
    border-radius: $border-radius;
    height: $box-height;
    color: lighten($black-font, 18%);
    font-size: 11px;
    background: $gray-light-5;
    font-weight: $regular;
    &:focus {
      box-shadow: none;
      border-color: $gray-light-3;
    }
  }

  &-input,
  &-input-group {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $gray-3;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $gray-3;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $gray-3;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $gray-3;
    }
  }

  // File upload

  &-file-upload-input {
    box-shadow: none;
    border: 1px solid transparent;
    background: $gray-light-5;
    border-radius: 4px;
  }

  &-file-input {
    border-radius: $border-radius;
    input {
      width: 100%;
    }
  }

  // Button

  &-button {
    box-shadow: none;
    outline: none;
    transition: all $general-change;
    min-height: $box-height;
    border-radius: $border-radius;
    background: $gray-light-5;
    font-size: 13px;
    color: rgba($gray-1, 0.7);

    &:not(.bp3-intent-primary),
    &:not(.bp3-intent-success),
    &:not(.bp3-intent-warning),
    &:not(.bp3-intent-danger) {
      .bp3-icon {
        color: $gray-1;
      }
    }

    &.bp3-intent-primary,
    &.bp3-intent-success,
    &.bp3-intent-warning,
    &.bp3-intent-danger {
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }

    &.bp3-intent-primary {
      &:hover {
        background: darken($primary, 5%);
      }
    }

    &.bp3-intent-success {
      &:hover {
        background: darken($success, 5%);
      }
    }

    &.bp3-intent-warning {
      &:hover {
        background: darken($orange3, 5%);
      }
    }

    &.bp3-intent-danger {
      background: $error;
      &:hover {
        background: darken($error, 5%);
      }
    }

    &:not([class*='bp3-intent-']) {
      background: $gray-light-5;
      box-shadow: none;
      color: $gray-2;
      &:hover {
        box-shadow: none;
        background: darken($gray-light-5, 2%);
      }
      &:disabled,
      &.bp3-disabled {
        background: $gray-light-5;
        border: 1px solid $gray-light-5;
        color: darken($gray-light-1, 10%);
      }
    }
    &:hover {
      border-color: transparent;
      box-shadow: none;
      background: darken($gray-light-5, 3%);
    }
  }
  &-progress-bar {
    &.bp3-intent-primary {
      .bp3-progress-meter {
        background-color: $primary;
      }
    }
  }
}
