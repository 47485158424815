// Tags

.tag {
  border: 1px solid rgba($primary, 0.1);
  background: #fff;
  margin-right: 10px;
  border-radius: 20px;
  display: inline-block;
  padding: 3px 10px;
  color: $gray-1;
  font-weight: 500;
  font-size: 12px;
  min-width: 84px;
  text-align: center;
}
